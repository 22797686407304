import React, { useState } from 'react';
import Immutable from 'immutable';
import moment from 'moment';

import AirlineLogo from '@/src/components/Logo/AirlineLogo';
import VirtualLogo from '@/src/components/Logo/VirtualLogo';
import ReactToolTip from '@/src/components/Base/reactToolTip';

import Converter from '../../../sources/list/converter';
import HOVER_MESSAGE from '../../../constants/list/enums/hoverMessage';
import { LUGGAGE_DIRECT_TYPE_V2, LUGGAGE_DIRECT_TYPE, TRANSIT_TAG_TYPE } from '../../../constants/common/enum/common';
import CONSTS from '../../../constants/list/constConfig';

import { getFlightComfortPromise } from '../../../actions/list/popups';
import DeferredContent from '../../Base/deferredContent';
import Tooltip from '../common/tooltip';
import { getIfScopeDomestic } from '../../../sources/list/scope';
import { getIfShowBridgePercent } from '../common/getIfShowBridgePercent';
import { VIRTUAL_FLIGHT_TEXT } from '../../../constants/list/enums/virtualFlightInfo';

const FlightDetail = (props) => {
	const {
		segment,
		priceList,
		currentSegmentSeq,
		hiddenMultiPU,
		visaLuggage,
		getParams,
		cacheKey,
		tongChengFlightEnum,
		flightIndex,
		getTabContent,
	} = props;

	let segmentNo = segment.get('segmentNo'),
		// planeAgeSwitch = globalSwitch.get('planeAgeSwitch'),
		flightList = segment.get('flightList'),
		minAvgPriceWithTax = priceList.map((t) => t.get('avgPriceWithTax')).min(),
		theMinPrice = priceList.find((t) => t.get('avgPriceWithTax') === minAvgPriceWithTax), //最低运价
		productTypeWrapperOfMinPrice = theMinPrice.get('productTypeEnum'),
		getTransferCheckInCitiesOfPrice = (price) =>
			price.getIn(['transferCheckInCities']).filter((city) => city.get('segmentNo') == currentSegmentSeq + 1),
		theTransferCheckinCitiesOfMinPrice = getTransferCheckInCitiesOfPrice(theMinPrice), //最低运价对应的当前程多票中转拆分城市
		visaLuggageDirectInfoList = visaLuggage && visaLuggage.visaLuggageDirectInfoList,
		transitReminder = segment.getIn(['transferRemark', 'transitReminder']);

	const [sequencesDetailUnfold, setSequencesDetailUnfold] = useState(new Array(flightList.size).fill(false));

	const [unfoldTongChengFlight, setUnfoldTongChengFlight] = useState(false);
	// 准点率和延误时长
	let getComfortContent = (isDep, punctuality, bridge) => {
		const showBridge = getIfShowBridgePercent(bridge);
		let isHasComfort = punctuality || showBridge;
		if (isHasComfort) {
			return (
				<div className="info-item">
					{punctuality ? (
						<div className="time">
							{isDep ? '出发' : '到达'}准点率：{punctuality}
						</div>
					) : null}
					{showBridge ? <div className="bridge">廊桥率：{bridge}</div> : null}
				</div>
			);
		}
		return null;
	};

	let getLuggageListOfStop = (sequenceNo) => {
		if (visaLuggageDirectInfoList && visaLuggageDirectInfoList.size > 0) {
			let luggageOfStops = Immutable.fromJS([]),
				luggageDirectList = visaLuggageDirectInfoList.getIn([0, 'luggageDirectList']); //取其中一条运价即可
			if (luggageDirectList) {
				for (let i = 0; i < luggageDirectList.size; i++) {
					let luggage = luggageDirectList.get(i);
					if (
						luggage.get('segmentNo') == segmentNo &&
						luggage.get('sequenceNo') == sequenceNo &&
						!!luggage.get('luggageDirectTypeOfStops')
					)
						luggageOfStops = luggage.get('luggageDirectTypeOfStops');
				}
				return luggageOfStops;
			}
		}
		return null;
	};

	let getTransferLuggage = (sequenceNo) => {
		if (visaLuggageDirectInfoList && visaLuggageDirectInfoList.size > 0) {
			let singlePuLuggage = null,
				multiPuLuggage = null,
				singlePuLuggageEnum = null,
				multiPuLuggageEnum = null;

			visaLuggageDirectInfoList.forEach((v) => {
				let luggageList = v.get('luggageDirectList');
				if (luggageList && luggageList.size > 0) {
					if (v.get('isMultiPriceUnit')) {
						// 取严
						// 如果运价是多票，而且存在行李非直挂的运价，则取非直挂
						for (let i = 0; i < luggageList.size; i++) {
							let luggage = luggageList.get(i);
							if (multiPuLuggage && multiPuLuggage.get('luggageDirectType') == 2) break;
							if (luggage.get('segmentNo') == segmentNo && luggage.get('sequenceNo') == sequenceNo) {
								if (
									multiPuLuggage &&
									multiPuLuggage.get('luggageDirectType') == 0 &&
									luggage.get('luggageDirectType') == 1
								)
									continue;
								multiPuLuggage = luggage;
							}
						}
					} else if (!singlePuLuggage) {
						// 非多票取其一即可
						for (let i = 0; i < luggageList.size; i++) {
							let luggage = luggageList.get(i);
							if (singlePuLuggage) break;
							if (luggage.get('segmentNo') == segmentNo && luggage.get('sequenceNo') == sequenceNo)
								singlePuLuggage = luggage;
						}
					}
				}
			});

			if (singlePuLuggage) {
				let luggageType = singlePuLuggage.get('luggageDirectType');
				singlePuLuggageEnum = LUGGAGE_DIRECT_TYPE.get(luggageType + '') || LUGGAGE_DIRECT_TYPE.UNKNOWN;
			}

			if (multiPuLuggage) {
				let multiPuluggageType = multiPuLuggage.get('luggageDirectType');
				multiPuLuggageEnum = LUGGAGE_DIRECT_TYPE.get(multiPuluggageType + '') || LUGGAGE_DIRECT_TYPE.UNKNOWN;
			}

			return {
				singlePuLuggageEnum: singlePuLuggageEnum,
				multiPuLuggageEnum: multiPuLuggageEnum,
			};
		}
		return null;
	};
	// 获取经停内容
	let getStopListContent = (stopList, sequenceNo, duration, isVirtualFlight) => {
		let luggageOfStops = getLuggageListOfStop(sequenceNo);
		return (
			<div className="fly-time stopover">
				<div className="fly-time-total">
					{isVirtualFlight ? '行驶' : '飞行'} {duration} (含经停）
				</div>
				{stopList.map((stop, index) => {
					let luggageDirectTypeText = null,
						luggageType = 1,
						stopLuggageEnum = null,
						luggageProductTagId = `fly-time-tags-luggage-${index}`;
					if (luggageOfStops && luggageOfStops.size > 0) {
						luggageType = luggageOfStops.get(index);
						stopLuggageEnum =
							LUGGAGE_DIRECT_TYPE_V2.get(luggageType + '') || LUGGAGE_DIRECT_TYPE_V2.UNKNOWN;
						luggageDirectTypeText = stopLuggageEnum.value.preview;

						return (
							<div className="fly-time-stopover" key={index}>
								<span className="time">
									经停{stop.get('cityName')}&nbsp;{stop.get('airportName')}&nbsp; 停留
									{stop.get('duration') > 0
										? Converter.formatMinAsDayHourMin(stop.get('duration'))
										: '时长需咨询航司'}
								</span>

								{luggageDirectTypeText ? (
									<ul className="fly-time-tags">
										<li
											id={luggageProductTagId + '_' + flightIndex}
											className={luggageType == 1 ? 'luggage-direct' : ''}
										>
											{luggageDirectTypeText}
											<ReactToolTip
												zIndex={2000}
												group="product-tag-hover"
												parentId={luggageProductTagId + '_' + flightIndex}
												toolTipContent={<Tooltip content={stopLuggageEnum.value.text} />}
											/>
										</li>
									</ul>
								) : null}
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};

	// 获取历史延误时长
	let getDelaytimeContent = (punctualityInfo, arrivalDateTime) => {
		if (punctualityInfo) {
			let delaytime = punctualityInfo.get('arrivalDelaytime');
			return typeof delaytime === 'undefined' ? null : (
				<span className="delay-time">
					平均到达时间
					{moment(arrivalDateTime).add(delaytime, 'minutes').format('HH:mm')}
				</span>
			);
		}

		return null;
	};

	// 中转提示
	let getVirtualFlightTransferInfo = (preFlight, afterFlight, isGuide) => {
		let isPrevVirtual = preFlight && preFlight.get('virtualFlight'),
			isNextVirtual = afterFlight && afterFlight.get('virtualFlight');
		// 三种类型
		if (!isPrevVirtual && isNextVirtual) {
			return isGuide
				? `飞机换乘${VIRTUAL_FLIGHT_TEXT[afterFlight.get('trafficType')]?.value || ''}`
				: `换乘${VIRTUAL_FLIGHT_TEXT[afterFlight.get('trafficType')].value || ''}`;
		}

		if (isPrevVirtual && !isNextVirtual) {
			return isGuide ? `${VIRTUAL_FLIGHT_TEXT[preFlight.get('trafficType')]?.value || ''}换乘飞机` : '换乘飞机';
		}

		return isGuide ? '' : '中转';
	};
	// 中转点上获取过境签提示
	let getTransferVisaTip = (segmentNo, sequenceNo) => {
		let visa = null;
		if (visaLuggage) {
			let { leakedVisaInfoList } = visaLuggage;
			if (leakedVisaInfoList) {
				for (let i = 0; i < leakedVisaInfoList.length; i++) {
					let leakedVisaInfo = leakedVisaInfoList[i],
						{ leakedVisaTagType, visaInfoList } = leakedVisaInfo;
					if (
						leakedVisaInfo.segmentNo == segmentNo &&
						visaInfoList &&
						visaInfoList.some((t) => t.sequence == sequenceNo)
					) {
						if (leakedVisaTagType == 'NEED_VISA') {
							visa = TRANSIT_TAG_TYPE.NEED_VISA.value;
						} else if (leakedVisaTagType == 'NOT_NEED_VISA') {
							visa = TRANSIT_TAG_TYPE.NOT_NEED_VISA.value;
						}
						break;
					}
				}
			}
		}
		return visa;
	};
	// 中转时长提示
	let getFlightTransferTime = (transferDuration, arrivalDateTime, nextFlightDepartureDateTime) => {
		let moreThanOneDay =
				moment(moment(nextFlightDepartureDateTime).format('YYYY-MM-DD')).diff(
					moment(arrivalDateTime).format('YYYY-MM-DD'),
					'days'
				) >= 1,
			twoHours = 60 * 2;
		if (moreThanOneDay && transferDuration > twoHours) {
			return CONSTS.TRANSFER_DES.MORE_THAN_ONE_DAY_AND_MORE_THAN_2_HOURS;
		} else if (transferDuration <= twoHours) {
			let minutes = Converter.formatMinAsDayHourMin(transferDuration);
			return {
				...CONSTS.TRANSFER_DES.LESS_THAN_2_HOURS,
				info: CONSTS.TRANSFER_DES.LESS_THAN_2_HOURS.info.replace(/\[MINUTES\]/, minutes),
			};
		} else {
			return null;
		}
	};
	// 中转时长提示详情
	let getTransferTimeInfoTip = (transferTimeInfo) => {
		let result = '';

		result = <span className="detail-info">{transferTimeInfo.info}</span>;

		return result
			? genTransferItem({
					title: transferTimeInfo.title,
					key: 'transferTimeInfo',
					good: false,
					content: result,
			  })
			: null;
	};
	let genTransferItem = ({ title, content, key, good }) => {
		return (
			<div key={key} className="detail-item">
				<span className={`name ${good ? 'good' : 'bad'}`}>{title}</span>
				<span className="detail-info">{content}</span>
			</div>
		);
	};
	let getPromptContent = ({ flight, sequenceNo, nextFlight, transferDuration, arrivalCityName }) => {
		let result = [];
		let arrivalAirportName = flight.get('arrivalAirportName');

		// 不同航站楼
		if (flight.get('differentTerminal')) {
			result.push({
				...HOVER_MESSAGE.DIFFERENT_TERMINAL.value,
				text: getTerminalDetailTip(HOVER_MESSAGE.DIFFERENT_TERMINAL.value, arrivalCityName),
			});
		}
		// 不同机场
		if (flight.get('differentAirport')) {
			result.push({
				...HOVER_MESSAGE.TRANSFER_AIRPORT.value,
				text: getTransferAirportDetailTip({
					transferAirportDetail: HOVER_MESSAGE.TRANSFER_AIRPORT.value,
					arrivalAirportName: arrivalAirportName,
					nextFlight,
				}),
			});
		}

		// 是否行李直达
		let isSameLuggage = true, //行李是否直达不一致
			transferLuggage = getTransferLuggage(sequenceNo);
		if (transferLuggage) {
			let multiPuLuggageEnum = transferLuggage.multiPuLuggageEnum,
				singlePuLuggageEnum = transferLuggage.singlePuLuggageEnum;

			if (singlePuLuggageEnum && multiPuLuggageEnum)
				isSameLuggage = Immutable.is(singlePuLuggageEnum, multiPuLuggageEnum);

			if (isSameLuggage) {
				let luggageTypeEnum = singlePuLuggageEnum || multiPuLuggageEnum;
				if (luggageTypeEnum && luggageTypeEnum.value.preview) {
					result.push({
						...luggageTypeEnum.value,
						text: getTransferLuggageDetailTip(luggageTypeEnum.value, arrivalCityName),
					});
				}
			}
		}

		// 过境签
		let visaTag = getTransferVisaTip(segmentNo, sequenceNo);
		if (visaTag) {
			let tip = getVisaTip(flight);
			result.push({ ...visaTag, text: tip });
		}

		// 中转停留时长
		let transferTimeInfo =
			nextFlight &&
			getFlightTransferTime(transferDuration, flight.get('arrivalDateTime'), nextFlight.get('departureDateTime'));
		// transferTimeInfo && transferTimeDes.push({ ...transferTimeInfo, arrivalCityName })
		if (transferTimeInfo && transferTimeInfo.title) {
			result.push({ ...transferTimeInfo, text: getTransferTimeInfoTip(transferTimeInfo, arrivalCityName) });
		}

		// IST服务
		if (flight.get('istTransitService')) {
			result.push({
				...HOVER_MESSAGE.IST_FAST_PASS_REMINDER.value,
				text: genTransferItem({
					...HOVER_MESSAGE.IST_FAST_PASS_REMINDER.value,
					content: HOVER_MESSAGE.IST_FAST_PASS_REMINDER.value.text,
					key: 'istFastPassReminder',
				}),
			});

			result.push({
				...HOVER_MESSAGE.IST_TRANSIT_SERVICE.value,
				text: genTransferItem({
					...HOVER_MESSAGE.IST_TRANSIT_SERVICE.value,
					content: HOVER_MESSAGE.IST_TRANSIT_SERVICE.value.text,
					key: 'istTransitService',
				}),
			});
		}

		// 换乘指南
		if (getIfScopeDomestic()) {
			transitReminder &&
				result.push({
					...HOVER_MESSAGE.VIRTAUL_FLIGHT_REMARK.value,
					text: genTransferItem({
						...HOVER_MESSAGE.VIRTAUL_FLIGHT_REMARK.value,
						content: transitReminder,
						key: 'transferRemarks',
					}),
				});
		} else {
			if (
				segment.get('virtualFlightRemarks') &&
				segment
					.get('virtualFlightRemarks')
					.some((item) => item.get('segmentNo') == segmentNo && item.get('sequenceNo') == sequenceNo) &&
				getVirtualFlightTransferInfo(flightList.get(sequenceNo - 1), flightList.get(sequenceNo), true)
			) {
				result.push({
					...HOVER_MESSAGE.VIRTAUL_FLIGHT_REMARK.value,
					text: getVirtualFlightExplain(flight, HOVER_MESSAGE.VIRTAUL_FLIGHT_REMARK.value),
				});
			}
		}
		// 预订说明
		const descriptions = segment.getIn(['transferRemark', 'descriptions']),
			transferServicePackages = segment.getIn(['transferRemark', 'servicePackages']);

		if (descriptions && descriptions.size && !tongChengFlightEnum) {
			result.push({
				...HOVER_MESSAGE.BOOK_NOTE.value,
				text: getBookNote(HOVER_MESSAGE.BOOK_NOTE.value, descriptions),
			});
		}
		// 中转服务包
		if (transferServicePackages && transferServicePackages.size) {
			transferServicePackages.toJS().map((servicePackage, i) => {
				result.push({
					...HOVER_MESSAGE.TRANSFER_SERVICE_PACKAGES.value,
					title: servicePackage.title,
					preview: servicePackage.title,
					text: getTransferServicePackage(servicePackage, i),
				});
			});
		}

		// 对所有标签进行分类，good sortIndex
		// title: '跨天中转',
		// preview: '跨天中转',
		// good: false,
		// sortIndex: 6,
		let goodResult = result.filter((t) => t.good === true).sort((t1, t2) => t1.sortIndex - t2.sortIndex),
			badResult = result.filter((t) => !t.good).sort((t1, t2) => t1.sortIndex - t2.sortIndex),
			renderTips = [],
			renderDesc = [],
			seperator = <span className="seperator">|</span>;

		goodResult &&
			goodResult.forEach((t, i) => {
				renderTips.push(
					<span className="good" key={'good' + i}>
						{t.preview || t.title} {i != goodResult.length - 1 ? seperator : ''}{' '}
					</span>
				);
				t.text && renderDesc.push(t.text);
			});
		badResult &&
			badResult.forEach((t, i) => {
				let showSplitLine = (i == 0 && goodResult && goodResult.length) || i != 0;
				renderTips.push(
					<span className="bad" key={'bad' + i}>
						{showSplitLine ? seperator : ''} {t.preview || t.title}{' '}
					</span>
				);
				t.text && renderDesc.push(t.text);
			});
		return {
			renderTips,
			renderDesc,
		};
	};

	// 中转托运行李的提示
	let getTransferLuggageDetailTip = (luggageDetail) => {
		let luggageDetailList = '';

		luggageDetailList = (
			<div className="exp-dl">
				<div className="exp-dd">
					<div className="exp-text">{luggageDetail.text}</div>
				</div>
			</div>
		);

		return luggageDetailList
			? genTransferItem({
					title: luggageDetail.title,
					key: 'luggageDetail',
					good: luggageDetail.good,
					content: luggageDetailList,
			  })
			: null;
	};
	let getExtendVisaTip = (sequenceNo) => {
		let result = '',
			visa = '';
		if (visaLuggageDirectInfoList && visaLuggageDirectInfoList.size > 0) {
			for (let i = 0; i < visaLuggageDirectInfoList.size; i++) {
				let current = visaLuggageDirectInfoList.get(i),
					visaList = current.get('visaList');

				if (!visaList || visaList.size == 0) continue;

				visa = visaList.find(
					(visa) =>
						visa.get('segmentNo') == segmentNo &&
						visa.get('sequenceNo') == sequenceNo &&
						visa.get('messageList') &&
						visa.get('messageList').size > 0
				);
				if (visa) {
					let needVisa = visa.get('needVisa');
					result = genTransferItem({
						title: needVisa ? HOVER_MESSAGE.NEED_VISA.value.title : HOVER_MESSAGE.NOT_NEED_VISA.value.title,
						key: 'visa',
						content: visa.get('messageList'),
						good: !needVisa,
					});
					break;
				}
			}
		}
		return result;
	};

	let getVisaTip = (flight) => {
		return getExtendVisaTip(flight.get('sequenceNo'));
	};
	// 不同机场转机的提示
	let getTransferAirportDetailTip = ({ transferAirportDetail, arrivalAirportName, nextFlight }) => {
		let transferAirportTip = transferAirportDetail.text
				.replace(/DEPAIRPORT/g, arrivalAirportName)
				.replace(/AIRAIRPORT/g, nextFlight.get('departureAirportName')),
			transferAirportList;

		transferAirportList = <span className="detail-info">{transferAirportTip}</span>;
		return transferAirportList
			? genTransferItem({
					title: transferAirportDetail.title,
					key: 'transferAirport',
					good: false,
					content: transferAirportList,
			  })
			: null;
	};
	// 不同航站楼中转的提示
	let getTerminalDetailTip = (terminalDetail) => {
		let transferTerminalList = '',
			transferTerminal = terminalDetail,
			differentTerminalTip = transferTerminal.text;

		transferTerminalList = <span className="detail-info">{differentTerminalTip}</span>;

		return transferTerminalList
			? genTransferItem({
					title: transferTerminal.title,
					key: 'terminal',
					good: false,
					content: transferTerminalList,
			  })
			: null;
	};

	// 虚拟航班换乘指南
	let getVirtualFlightExplain = (flight, virtualFlightRemarkDetail) => {
		if (segment.get('virtualFlightRemarks')) {
			let virtualFlightRemark = segment
				.get('virtualFlightRemarks')
				.find(
					(item) => item.get('segmentNo') === segmentNo && item.get('sequenceNo') == flight.get('sequenceNo')
				);
			if (virtualFlightRemark) {
				let transferRemark =
					virtualFlightRemark.get('transferRemark') || virtualFlightRemark.get('checkInRemark');
				let transferInfo = getVirtualFlightTransferInfo(
					flightList.get(virtualFlightRemark.get('sequenceNo') - 1),
					flightList.get(virtualFlightRemark.get('sequenceNo')),
					true
				);
				if (transferInfo && transferRemark) {
					return genTransferItem({
						title: virtualFlightRemarkDetail.title,
						content: `${flightList.getIn([
							virtualFlightRemark.get('sequenceNo') - 1,
							'arrivalCityName',
						])}${transferInfo}: ${transferRemark}`,
						key: 'virtualFlightRemarks',
						good: virtualFlightRemarkDetail.good,
					});
				}
			}
		}
		return '';
	};
	let getBookNote = (bookNote, descriptions) => {
		let contentList = [];
		contentList = descriptions.toJS().map((des, i) => {
			if (descriptions.length > 1) {
				return (
					<div key={i}>
						{i + 1}.{des}
					</div>
				);
			} else {
				return <span key={i}>{des}</span>;
			}
		});
		return genTransferItem({
			title: bookNote.title,
			key: 'booknote',
			good: false,
			content: contentList,
		});
	};
	let getTransferServicePackage = (servicePackage, index) => {
		const { title, details } = servicePackage;
		let contentList = [];
		contentList = details.map((detail, i) => {
			const { desc, title } = detail;
			return (
				<div key={i}>
					<span className="sub-title">{title}:</span>
					{desc}
				</div>
			);
		});
		return genTransferItem({
			title: title,
			key: `servicePackage${index}`,
			good: false,
			content: contentList,
		});
	};
	let sequenceDetail = (index, keppUnfold, e) => {
		e.stopPropagation();
		setSequencesDetailUnfold(
			sequencesDetailUnfold.map((unfold, key) => {
				if (key == index) {
					if (keppUnfold) {
						return true;
					} else {
						return !unfold;
					}
				}
				return unfold;
			})
		);
	};

	let getFlightListContent = () => {
		return flightList.map((flight, index) => {
			let isVirtualFlight = flight.get('virtualFlight'),
				trafficType = flight.get('trafficType'),
				operateFlightNo = flight.get('operateFlightNo'),
				departureTerminal = flight.get('departureTerminal'),
				arrivalTerminal = flight.get('arrivalTerminal'),
				operateAirlineCode = flight.get('operateAirlineCode'),
				operateAirlineName = flight.get('operateAirlineName'),
				isShare = getIfScopeDomestic() ? !!operateAirlineCode : !!operateAirlineName,
				transferDuration = flight.get('transferDuration'),
				arrivalCityName = flight.get('arrivalCityName'),
				departureDateTime = flight.get('departureDateTime'),
				arrivalDateTime = flight.get('arrivalDateTime'),
				diffDepDateTime =
					index != 0
						? moment(Converter.extractDate(departureDateTime, 'YYYY-MM-DD')).diff(
								moment(
									Converter.extractDate(
										flightList.get(index - 1).get('arrivalDateTime'),
										'YYYY-MM-DD'
									)
								),
								'day'
						  )
						: 0,
				diffDateTime = moment(Converter.extractDate(arrivalDateTime, 'YYYY-MM-DD')).diff(
					moment(Converter.extractDate(departureDateTime, 'YYYY-MM-DD')),
					'day'
				),
				isLastFlight = index === flightList.size - 1, //最后一程
				hasProductTag =
					!isLastFlight &&
					theTransferCheckinCitiesOfMinPrice.some((city) => city.get('cityName') === arrivalCityName),
				productTagId = `productTag_${theMinPrice.get('routeSearchToken').replace(/[^a-zA-z0-9_]/g, '')}`,
				productTagTooltipContent = hasProductTag
					? productTypeWrapperOfMinPrice.tag.value.getLabelTooltip(
							productTypeWrapperOfMinPrice,
							theMinPrice,
							currentSegmentSeq
					  )
					: null,
				nextFlight = !isLastFlight ? flightList.get(index + 1) : null,
				{ renderTips, renderDesc } = getPromptContent({
					flight,
					sequenceNo: flight.get('sequenceNo'),
					nextFlight,
					transferDuration,
					arrivalCityName,
				}),
				// promptTags = !isLastFlight ? [getVisaTip(flight), getTransferLuggageDetailTip(flight),
				// getTransferAirportDetailTip(flight, flightList.get(index + 1)), getTerminalDetailTip(flight), getVirtualFlightExplain(flight)] : [],
				flightComfortPromise = getFlightComfortPromise(
					getParams(flight, index, priceList, currentSegmentSeq, segment),
					cacheKey,
					index
				);
			// 香港隔离
			const HKIsolation = flight.get('isolationDescription') ? (
				<span style={{ color: '#FF7700' }}>{flight.get('isolationDescription')}</span>
			) : null;
			return (
				<React.Fragment key={`flight_${index}`}>
					<div className={`flight-item ${isLastFlight ? 'last' : ''}`}>
						<div className="airline">
							<AirlineLogo
								className="logo"
								airlineCode={flight.get('subMarketAirlineCode') || flight.get('marketAirlineCode')}
								size="48"
							/>
							<span className="name">
								{flight.get('marketAirlineName')} {flight.get('flightNo')}
							</span>
							{!isVirtualFlight ? (
								<DeferredContent
									promise={flightComfortPromise}
									loading={''}
									then={(comfort) => {
										let punctualityInfo =
											comfort && Immutable.fromJS(comfort['punctualityInfo'] || {});
										if (punctualityInfo.size <= 0) return null;
										return (
											<React.Fragment>
												{getDelaytimeContent(punctualityInfo, flight.get('arrivalDateTime'))}
												<span
													className="info"
													id={`flight_info_delay_${index} _${flightIndex}`}
												>
													说明
													<div className="delay-time-info">
														<div className="info-main">
															{getComfortContent(
																true,
																punctualityInfo.get('departurePunctuality'),
																punctualityInfo.get('departureBridge')
															)}
															{getComfortContent(
																false,
																punctualityInfo.get('arrivalPunctuality'),
																punctualityInfo.get('arrivalBridge')
															)}
														</div>
														<div className="info-des">
															<p>准点率：航班最近30次准点出发/到达情况。</p>
															{getIfShowBridgePercent(
																punctualityInfo.get('departureBridge')
															) ||
															getIfShowBridgePercent(
																punctualityInfo.get('arrivalBridge')
															) ? (
																<p>廊桥率：航班停靠航站楼廊桥的概率。</p>
															) : null}
														</div>
													</div>
												</span>
											</React.Fragment>
										);
									}}
								/>
							) : null}
							{isShare ? (
								<div className="real-airline">
									<div className="airline-info">
										<span className="title">实际承运 </span>
										<span className="name">
											{isVirtualFlight && <VirtualLogo trafficType={trafficType} size="48" />}
											{!isVirtualFlight && operateAirlineCode && (
												<AirlineLogo airlineCode={operateAirlineCode} size="48" />
											)}
											{operateAirlineName} {operateFlightNo}
										</span>
										<span className="des">请前往实际承运的航司值机柜台办理登机手续</span>
									</div>
								</div>
							) : null}
						</div>

						<div className="airline-detail">
							<div className="trip-box">
								<div className="depart">
									{/* {
                                        diffDepDateTime != 0 ? <span className="date">{moment(flight.get('departureDateTime')).format('MM-DD')}</span> : null
                                    } */}
									<span className="dot"></span>
									<span className={`date-time ${diffDepDateTime ? 'hightlight' : ''}`}>
										{moment(flight.get('departureDateTime')).format('MM-DD')}
									</span>
									<span className="time">
										{moment(flight.get('departureDateTime')).format('HH:mm')}
									</span>
									<span className="airport">
										<div>
											{flight.get('departureAirportCode')}{' '}
											{`${flight.get('departureAirportName')}`}&nbsp;{' '}
											{Converter.getTerminalName(departureTerminal)}
										</div>
										{isVirtualFlight ? (
											<div>
												{' '}
												{flight.get('departureAirportEnName')} &nbsp;{' '}
												{Converter.getTerminalName(departureTerminal)}
											</div>
										) : null}
									</span>
								</div>
								{flight.get('stopList').size ? (
									getStopListContent(
										flight.get('stopList'),
										flight.get('sequenceNo'),
										Converter.formatMinAsDayHourMin(flight.get('duration')),
										isVirtualFlight
									)
								) : (
									<div className="fly-time">
										<span className={`${isVirtualFlight ? 'highlight' : ''}`}>{`${
											isVirtualFlight ? '行驶' : '飞行'
										}`}</span>
										&nbsp;
										<span>{Converter.formatMinAsDayHourMin(flight.get('duration'))}</span>
									</div>
								)}

								<div className="arrive">
									{/* {
                                        diffDateTime != 0 ? <span className="date">{moment(flight.get('arrivalDateTime')).format('MM-DD')}</span> : null
                                    } */}
									<span className="dot"></span>
									<span className={`date-time ${diffDateTime ? 'hightlight' : ''}`}>
										{moment(flight.get('arrivalDateTime')).format('MM-DD')}
									</span>
									<span className="time">
										{moment(flight.get('arrivalDateTime')).format('HH:mm')}
									</span>
									<span className="airport">
										<div>
											{flight.get('arrivalAirportCode')} {`${flight.get('arrivalAirportName')}`}
											&nbsp;
											{Converter.getTerminalName(arrivalTerminal)}
										</div>
										{isVirtualFlight ? (
											<div>
												{' '}
												{flight.get('arrivalAirportEnName')} &nbsp;{' '}
												{Converter.getTerminalName(arrivalTerminal)}
											</div>
										) : null}
									</span>
								</div>
							</div>
						</div>
						{/* <DeferredContent promise={flightComfortPromise} loading={''} then={(comfort) => (
                            <FlightComfort comfort={comfort} planeAgeSwitch={planeAgeSwitch} />
                        )} /> */}
					</div>
					{!isLastFlight ? (
						<div
							onClick={(e) => sequenceDetail(index, true, e)}
							className={`detail-drop-down transfer-detail ${
								sequencesDetailUnfold[index] ? 'active' : ''
							}`}
						>
							<div className="main-info">
								<span className="dot"></span>
								<span className="city">
									{arrivalCityName}&nbsp;
									{HKIsolation
										? null
										: getVirtualFlightTransferInfo(flight, flightList.get(index + 1))}
								</span>
								{HKIsolation || (transferDuration > 0 ? (
									<span className="stay-time">
										停留{Converter.formatMinAsDayHourMin(transferDuration)}
									</span>
								) : null)}
								&nbsp;
								{!hiddenMultiPU && hasProductTag ? (
									<span id={productTagId + '_' + flightIndex}>
										{productTypeWrapperOfMinPrice.tag.value.getFlightRowLabel(
											productTypeWrapperOfMinPrice,
											productTagId
										)}
									</span>
								) : null}
								{!hiddenMultiPU && hasProductTag ? (
									<ReactToolTip
										zIndex={2000}
										group="product-tag-hover"
										parentId={productTagId + '_' + flightIndex}
										toolTipContent={productTagTooltipContent}
									/>
								) : null}
								<ul className="tags">
									{renderTips.map((item, index) => {
										return <li key={index}>{item}</li>;
									})}
								</ul>
								{renderTips.length ? (
									<React.Fragment>
										<span className="show-detail">
											展开
											<i className="icon-arrow">&#xe78c;</i>
										</span>
										<span className="hide-detail" onClick={(e) => sequenceDetail(index, false, e)}>
											收起
											<i className="icon-arrow">&#xe78e;</i>
										</span>
									</React.Fragment>
								) : null}
							</div>
							<div className="detail-content">
								{renderDesc.map((item) => {
									return item;
								})}
							</div>
						</div>
					) : null}
				</React.Fragment>
			);
		});
	};
	const title = (tongChengFlightEnum?.label || '通程航班') + '专享';
	return (
		<React.Fragment>
			<div className="flight-item-contents">
				{
					<div className="segment-header">
						{getTabContent({ flightIndex, duration: segment.get('duration') })}
					</div>
				}
				{getFlightListContent()}
			</div>
			{/* <div className="disclaimer">舒适度数据由飞友科技提供，实际承运航司可能存在变更</div> */}
			{tongChengFlightEnum && tongChengFlightEnum.data.tags && tongChengFlightEnum.data.tags.length ? (
				<div className={`detail-drop-down drop-down-info ${unfoldTongChengFlight ? 'active' : ''}`}>
					<div className="main-info">
						<span className="dot"></span>
						<span className="title">{title}</span>
						<ul className="tags tags-separate">
							{tongChengFlightEnum.data.tags.map((tag) => (
								<li key={tag.key}>{tag.key}</li>
							))}
							{tongChengFlightEnum.data.bookingNotice ? <li className="notice">预订须知</li> : null}
						</ul>

						<span className="show-detail" onClick={() => setUnfoldTongChengFlight(true)}>
							展开
							<i className="icon-arrow">&#xe78c;</i>
						</span>
						<span className="hide-detail" onClick={() => setUnfoldTongChengFlight(false)}>
							收起
							<i className="icon-arrow">&#xe78e;</i>
						</span>
					</div>
					<div className="detail-content">
						{tongChengFlightEnum.data.tags.map((tag) => (
							<div className="detail-item" key={tag.key}>
								<span className="name">{tag.key}：</span>
								<span className="detail-info">{tag.value}</span>
							</div>
						))}
						{tongChengFlightEnum.data.bookingNotice ? (
							<div className="detail-item">
								<span className="name notice">预订须知：</span>
								<span className="detail-info">{tongChengFlightEnum.data.bookingNotice}</span>
							</div>
						) : null}
					</div>
				</div>
			) : null}
			{/* <div className="footer">
                <UbtBoundary tracker={ListUbtGenerator(!isLastSegment ? LIST_UBT_GROUP_TYPES.FLIGHT_DETAIL_TOLIST_CHOOSE_BUTTON_CLICK : LIST_UBT_GROUP_TYPES.FLIGHT_DETAIL_BACKLIST_CHOOSE_BUTTON_CLICK, LIST_UBT_KEY_TYPES.FLIGHT_DETAIL_CLICK_NOW_UPLOAD)}>
                    <a className="select-flight" onClick={onFooterBtnClick}>{!isLastSegment ? '选为去程' : '查看往返价格'}</a>
                </UbtBoundary>
            </div> */}
		</React.Fragment>
	);
};

export default FlightDetail;
