import React from 'react'

const defaultProps = {
    loading: <span>loading...</span>,
    then: value => <span>{value}</span>
}

export default class DeferredContent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: props.loading || defaultProps.loading,
            value: null,
            extValue: null,
            promise: props.promise,
            then: props.then
        }
    }

    invokeThen() {
        // 空铁航班情况，服务不接受火车cabinInfo，反序列化会失败
        this.state.promise.then(value =>
            { if (value) this.setState({ value: value?.el || value, extValue: value?.ext, loading: null }) }
        )
    }

    componentWillReceiveProps(nextProp) {
        if (this.state.promise !== nextProp.promise && this.state.then !== nextProp.then) {
            this.setState({
                loading: nextProp.loading || defaultProps.loading,
                value: null,
                promise: nextProp.promise,
                then: nextProp.then
            },()=>{
                this.invokeThen()
            })


        }
    }

    componentDidMount() {
        this.invokeThen()
    }

    render() {
        return this.state.value && this.props.then ? this.props.then(this.state.value, this.state.extValue || this.props.loading) : this.props.loading
    }
}
