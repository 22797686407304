import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from 'scroll-js';
import { addEventListener } from 'consolidated-events';
import PopupFlightDetail from '../../../../components/list/popups/popupFlightDetailV2';
import Converter from '../../../../sources/common/converter';
import {
	getTransitVisaRemarkList,
	genClearTransitVisaRemarkList,
	getFlightComfort,
	genFlightComfort,
	setEmptyFlightComfort,
} from '../../../../actions/list/popups';
import { transitVisaRemarkListSelector, visaLuggageDirectInfoSelector } from '../popups/popupFlightInfoSelector';
import { globalSwitchSelector, prevCondSelector } from '../flight/baseSelector';
import { getSelectedFlightSelector } from '../../sidebar/filterbar/filterbarSelector';
import { LIST_UBT_KEY_TYPES, UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES } from '../../../../ubt.v2/list';
import styles from './PopupFlightDetailContainerV2.module.scss';

// 滚动缓冲，这里的值为航程的padding值
const ScrollDelta = 33;

const getParams = (currentFlightList, index, priceList, currentSegmentSeq, segment) => {
	let cabinList = [];
	for (let i = 0; i < priceList.size; i++) {
		let p = priceList.get(i),
			cabin = '';
		cabin = p.get('cabin').split('|');
		if (cabin.length > 1) {
			cabin = cabin[currentSegmentSeq];
		} else {
			cabin = cabin[0];
		}
		cabin = cabin.replace(/[@]/gi, '');
		cabin = cabin.split('-');
		if (cabin.length > 1) {
			cabinList.indexOf(cabin[index]) < 0 && cabinList.push(cabin[index]);
		} else {
			cabinList.indexOf(cabin[0]) < 0 && cabinList.push(cabin[0]);
		}
	}
	let flightNo = currentFlightList.get('operateFlightNo')
		? currentFlightList.get('operateFlightNo')
		: currentFlightList.get('flightNo');

	let params = {
		airlineCode: segment.get('airlineCode'),
		arrivalCityCode:
			currentFlightList.get('stopList') && currentFlightList.get('stopList').size > 0
				? currentFlightList.getIn(['stopList', 0, 'cityCode'])
				: currentFlightList.get('arrivalCityCode'),
		arrivalDate: Converter.extractDate(currentFlightList.get('arrivalDateTime')),
		cabinList: cabinList,
		departureCityCode: currentFlightList.get('departureCityCode'),
		departureDate: Converter.extractDate(currentFlightList.get('departureDateTime')),
		flightNoList: [flightNo],
		aircraftCode: currentFlightList.get('aircraftCode'),
	};
	return params;
};

class PopupFlightDetailContainer extends React.Component {
	constructor(props) {
		super(props);
		this.setSegmentSeq = this.setSegmentSeq.bind(this);
		this.onFooterBtnClick = this.onFooterBtnClick.bind(this);
		this.contentWrapper = this.contentWrapper.bind(this);
		this.setContentPosition = this.setContentPosition.bind(this);
		this.onDetailContainerSrcoll = this.onDetailContainerSrcoll.bind(this);
		this.tabLeft = this.tabLeft.bind(this);
		this.tabRight = this.tabRight.bind(this);
		this.setTabPostion = this.setTabPostion.bind(this);
		this.getContents = this.getContents.bind(this);
		this.getTabContent = this.getTabContent.bind(this);
		this.onTabClick = this.onTabClick.bind(this);
		this.listenContainerScroll = true;
		this.detailContainer = null;
		this.tabContent = null;
		this.state = {
			priceList: null,
			segmentSeq: props.currentSegmentSeq,
			content: this.getContents(this.props.flightDetailInfos),
			showSwitchTab: false,
			tabPosition: 0,
		};
	}

	onFooterBtnClick() {
		let { onClickClose, onFooterBtnClick } = this.props;
		onFooterBtnClick && onFooterBtnClick();
		onClickClose();
	}
	contentWrapper({
		segment,
		priceList,
		currentSegmentSeq,
		transitVisaRemarkList,
		title = '航班信息',
		extTitle,
		headerClassName = 'popup-flightinfo',
		extFooter,
		hiddenMultiPU,
		visaLuggage,
		onlyVisa,
		tongChengFlightEnum,
		cleanItineraryId,
		globalSwitch,
		showTongChengRemark,
		key,
	}) {
		let segmentSeq = this.props.currentSegmentSeq;
		return (
			<PopupFlightDetail
				segment={segment}
				tongChengFlightEnum={tongChengFlightEnum}
				showTongChengRemark={showTongChengRemark}
				getParams={getParams}
				cacheKey={`flightDetail${cleanItineraryId}`}
				globalSwitch={globalSwitch}
				onlyVisa={onlyVisa}
				priceList={priceList}
				currentSegmentSeq={currentSegmentSeq}
				// flightInfoCardComfort={flightInfoCardComfort}
				transitVisaRemarkList={transitVisaRemarkList}
				visaLuggage={visaLuggage}
				title={title}
				extTitle={extTitle}
				headerClassName={headerClassName}
				extFooter={extFooter}
				hiddenMultiPU={hiddenMultiPU}
				onFooterBtnClick={this.onFooterBtnClick}
				isLastSegment={!!segmentSeq}
				key={key}
				flightIndex={key}
				getTabContent={this.getTabContent}
			/>
		);
	}
	getContents(flightDetailInfos) {
		return flightDetailInfos.map((info, index) => {
			return this.contentWrapper({ ...info, key: index });
		});
	}
	getTabItemWidth(index) {
		let item = this.tabContent.querySelectorAll('.segmentItem')[index];
		return item.offsetWidth;
	}
	getTabsWidth(index) {
		let totalWidth = 0,
			i = 0;
		while (i <= index) {
			totalWidth += this.getTabItemWidth(i);
			i++;
		}
		return totalWidth;
	}

	setTabPostion() {
		if (!this.tabContent) {
			return;
		}
		let { segmentSeq, tabPosition } = this.state,
			// tab可视区范围
			visibleMin = -tabPosition,
			visibleMax = visibleMin + this.tabCotainer.offsetWidth;
		// 让tab的位置始终保持在可视区内超长靠右显示
		let currentTabPositionRight = this.getTabsWidth(segmentSeq);
		let currentTabPositionLeft = currentTabPositionRight - this.getTabItemWidth(segmentSeq);
		if (currentTabPositionRight > visibleMax) {
			tabPosition = -(currentTabPositionRight - this.tabCotainer.offsetWidth);
		} else if (currentTabPositionLeft < visibleMin) {
			tabPosition = 0;
		}
		this.setState({
			tabPosition: tabPosition,
		});
	}
	setSegmentSeq({ seq, skipSetPosition }) {
		let setSegmentSeqState = () => {
			this.setState(
				{
					segmentSeq: seq,
				},
				() => {
					this.setTabPostion();
				}
			);
		};
		// skipSetPosition忽略设置内容的滚动位置
		if (skipSetPosition !== false) {
			this.setContentPosition({ segmentSeq: seq }).then(() => {
				setSegmentSeqState();
				this.listenContainerScroll = true;
			});
		} else {
			setSegmentSeqState();
		}
	}
	componentWillReceiveProps(nextProp) {
		if (
			nextProp.currentSegmentSeq != this.props.currentSegmentSeq ||
			nextProp.flightDetailInfos != this.props.flightDetailInfos
		) {
			this.setState({
				priceList: null,
				segmentSeq: nextProp.currentSegmentSeq,
				content: this.getContents(nextProp.flightDetailInfos),
				showSwitchTab: false,
				tabPosition: 0,
			});
		}
	}

	componentDidMount() {
		this.setContentPosition({ needAnimate: false, segmentSeq: this.props.currentSegmentSeq }).then(() => {
			setTimeout(() => {
				this.detailContainer &&
					addEventListener(this.detailContainer, 'scroll', () => {
						this.onDetailContainerSrcoll();
					});
				this.setTabStyle();
				this.setTabPostion();
			}, 100);
		});
	}
	setTabStyle() {
		if (this.tabContent && this.detailContainer && this.closeBtn) {
			let { content } = this.state,
				i = 0;
			if (content && content.length) {
				let tabsWidth = 0;
				while (i < content.length) {
					tabsWidth += this.getTabItemWidth(i);
					i++;
				}
				if (tabsWidth > this.detailContainer.offsetWidth - this.closeBtn.offsetWidth) {
					this.setState({
						showSwitchTab: true,
					});
				}
			}
		}
	}
	onTabClick(seq) {
		// 增加一个是否监听容器滚动的开关，防止点击tab的过程中，监听滚动时间导致tab切换过快
		this.listenContainerScroll = false;
		this.setSegmentSeq({ seq });
	}

	onDetailContainerSrcoll() {
		if (!this.listenContainerScroll) {
			return;
		}
		// 判断当前可视区内的出现的是哪一部分的内容
		let containerHeight = this.detailContainer.offsetHeight,
			containerScrollTop = this.detailContainer.scrollTop,
			rangeMax = containerScrollTop + containerHeight,
			rangeMin = containerScrollTop,
			{ content } = this.state,
			getItemHeight = (index) => {
				let item = this.detailContainer.querySelectorAll('.flight-item-contents')[index];
				return item.offsetHeight;
			},
			itemIsInVisibleView = (elementPostion) => {
				return elementPostion + ScrollDelta <= rangeMax && elementPostion + ScrollDelta >= rangeMin;
			};

		for (let index = 0; index < content.length; index++) {
			let itemPosition = 0,
				i = 0;
			while (index > i) {
				itemPosition += getItemHeight(i);
				i++;
			}
			let inVisibleView = itemIsInVisibleView(itemPosition);
			if (inVisibleView) {
				this.setSegmentSeq({ seq: index, skipSetPosition: false });
				break;
			}
		}
	}

	setContentPosition({ needAnimate, segmentSeq }) {
		// 设置内容滚动位置

		let getItemHeight = (index) => {
			let item = this.detailContainer.querySelectorAll('.flight-item-contents')[index];
			return item.offsetHeight;
		};

		let i = 0,
			itemPosition = 0;
		while (segmentSeq > i) {
			itemPosition += getItemHeight(i);
			i++;
		}
		return scrollTo(this.detailContainer, { top: itemPosition, duration: needAnimate === false ? 0 : 200 });
	}

	getTabContent({ flightIndex, duration }) {
		const { prevCond } = this.props,
			flightWay = prevCond.get('flightWay'),
			segment = prevCond.getIn(['flightSegments', flightIndex]),
			durationFragement = duration ? (
				<div className="duration">总时长{Converter.formatMinAsHourMin(duration)}</div>
			) : null,
			airline = (
				<span className="airline-line">
					{segment.get('departureCityName')}
					<span className="arrow"></span>
					{segment.get('arrivalCityName')}
				</span>
			);
		if (flightWay == 'S') {
			return (
				<React.Fragment>
					{airline}
					{durationFragement}
				</React.Fragment>
			);
		} else if (flightWay == 'D') {
			return (
				<React.Fragment>
					<span className="flight-way">
						{flightIndex == 0 ? '去程' : '返程'}
						<span className="colon">：</span>
					</span>
					{airline}
					{durationFragement}
				</React.Fragment>
			);
		} else if (flightWay == 'M') {
			return (
				<React.Fragment>
					<div className="flight-way">第{flightIndex + 1}程</div>
					{airline}
					{durationFragement}
				</React.Fragment>
			);
		}
		return null;
	}
	tabLeft() {
		this.setState({
			tabPosition: 0,
		});
	}
	tabRight() {
		const { content } = this.state;
		let totalTabWidth = this.getTabsWidth(content.length - 1) - this.tabCotainer.offsetWidth;
		this.setState({
			tabPosition: -totalTabWidth,
		});
	}

	render() {
		const { segmentSeq, showSwitchTab, tabPosition, content } = this.state;
		const { prevCond, onClickClose, showClose } = this.props,
			showTabSize = (content && content.length) || 0,
			flightWay = prevCond.get('flightWay');

		return (
			<div className="flight-info-container">
				{showClose === false ? null : (
					<i
						className={`ico-close-wb ${flightWay == 'M' ? 'ico-close-wb-large' : ''}`}
						ref={(elem) => (this.closeBtn = elem)}
						onClick={() => {
							onClickClose();
						}}
					>
						&#xe66b;
					</i>
				)}
				{flightWay != 'S' ? (
					<div className={`flight-info-header ${showSwitchTab ? 'flight-info-header-with-tab' : ''}`}>
						{showSwitchTab ? (
							<span className={`tab-control left `} onClick={this.tabLeft}>
								<i className="slider-prev-ico">&#xe602;</i>
							</span>
						) : null}
						<div className="flight-tab-list-container" ref={(ele) => (this.tabCotainer = ele)}>
							<ul
								className={`flight-tab-list ${flightWay == 'M' ? 'flight-tab-list-multi' : ''}`}
								ref={(ele) => (this.tabContent = ele)}
								style={{ marginLeft: tabPosition + 'px' }}
							>
								{prevCond &&
									prevCond.get('flightSegments').map((segment, index) => {
										return index < showTabSize ? (
											<UbtBoundary
												key={index}
												tracker={ListUbtGenerator(
													index == 0
														? LIST_UBT_GROUP_TYPES.FLIGHT_DETAIL_INBOUND_TAB_CLICK
														: LIST_UBT_GROUP_TYPES.FLIGHT_DETAIL_OUTBOUND_TAB_CLICK,
													LIST_UBT_KEY_TYPES.FLIGHT_DETAIL_CLICK_NOW_UPLOAD
												)}
											>
												<li
													key={index}
													className={`${segmentSeq == index ? 'active' : ''} segmentItem`}
													onClick={() => this.onTabClick(index)}
												>
													{this.getTabContent({ flightIndex: index, showDuration: false })}
												</li>
											</UbtBoundary>
										) : null;
									})}
							</ul>
						</div>
						{showSwitchTab ? (
							<span className={`tab-control right `} onClick={this.tabRight}>
								<i className="slider-next-ico">&#xe645;</i>
							</span>
						) : null}
					</div>
				) : null}

				<div className={styles['detail-contents']} ref={(ele) => (this.detailContainer = ele)}>
					{this.state.content}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	// flightInfoCardComfort: state.getIn(['list', 'popups', 'flightInfoCardComfort']),
	transitVisaRemarkList: transitVisaRemarkListSelector(state),
	visaLuggageDirectInfo: visaLuggageDirectInfoSelector(state),
	globalSwitch: globalSwitchSelector(state),
	selected: getSelectedFlightSelector(state),
	prevCond: prevCondSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	// getFlightInfoCardComfort(params, cacheKey) {
	//     dispatch(getFlightInfoCardComfort(params, cacheKey))
	// },
	getTransitVisaRemarkList(params, cacheKey) {
		dispatch(getTransitVisaRemarkList(params, cacheKey));
	},
	clearTransitVisaRemarkList() {
		dispatch(genClearTransitVisaRemarkList());
	},
	getFlightComfort(params, cacheKey, index) {
		dispatch(getFlightComfort(params, cacheKey, index));
	},
	setEmptyFlightComfort(cacheKey) {
		dispatch(setEmptyFlightComfort(cacheKey));
	},
	genFlightComfort(comfort) {
		dispatch(genFlightComfort(comfort));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupFlightDetailContainer);
