import Enum from 'enum';
import mergeConfig from 'src/constants/common/mergeConfig';
import Q_CONFIG_KEYS from 'src/constants/list/qConfigKeys.js';

// hover文案默认值
const _HOVER_MESSAGE = {
  MULTI_PU: '此为组合特价产品，机票将分开出票。出票后，入遇航变及退改签等问题，需分开处理；若产生相关费用将分开收取。',
  PRIORITIZING: {
    title: '',
  },
  // FLYER: {
  //     title: '飞人',
  //     hint: '携程为有紧急出行需求的用户推出的一项便捷服务',
  //     text: '该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间。'
  // },
  // SUPER_FLYER: {
  //     title: '超飞',
  //     hint: '携程为有紧急出行需求的用户推出的一项便捷服务',
  //     text: '该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间'
  // },
  FLIGHT_NIGHT: {
    title: '夜间航班',
    text: '指深夜起飞，凌晨或早上抵达的航班',
  },
  COMBINE_SPECIAL: '此为组合特价产品，机票将分开出票。出票后，如遇航变及退改签等问题，需分开处理；若产生相关费用将分开收取。',
  BUSSINESS: {
    title: '出行优选机票服务有保障，我们提供：',
    text: ['·快速出票；', '提供行程单或者机票发票，报销无忧；', '服务优先：尊享7×24小时的全天候服务'],
  },
  NO_VISA_RISK_MUILT_PU: {
    title: '换票换乘',
    text: [
      '多票组合是多张机票拼接的行程，预订成功后将分开出票。',
      '行李非直达，在换乘地“{city}”需重新办理托运（不同航程行李额可能有差异），请留出足够换乘时间。',
      '包括但不限于天气原因、乘客证件不齐（过境证件及签注）、乘客不合理安排换乘时间等导致无法搭乘任一航班，损失将由乘机人自行承担。',
    ],
  },
  HAS_VISA_RISK_MUILT_PU: {
    title: '换票换乘',
    text: [
      '多票组合是多张机票拼接的行程，其中在换乘地“{0}”需重新出入关，请注意查询相关过境地签证政策，签证政策因乘客国籍而异，确保备齐所需签证后再预订。',
      '行李非直达，在换乘地“{1}”需重新办理托运，请留出足够换乘时间。',
      '包括但不限于天气原因、乘客证件不齐（过境证件及签注）、乘客不合理安排换乘时间等导致无法搭乘任一航班，损失将由乘机人自行承担。',
    ],
  },
  TRANSFER_LUGGAGE: {
    preview: '行李非直达',
    title: '托运行李',
    text: '行李非直达，需在机场重新办理托运',
  },
  TRANSFER_LUGGAGE_V2: {
    preview: '重新托运行李',
    title: '重新托运行李',
    text: '行李非直达，需在机场重新办理托运',
  },
  DIRECT_LUGGAGE: {
    preview: '行李直达',
    title: '行李直达',
    text: '行李直达，无需在机场重新办理托运',
  },
  CONSULT_LUGGAGE: {
    preview: '',
    title: '咨询发始地',
    text: '是否需要办理中转托运，需咨询始发地值机柜台',
  },
  DIFFERENT_TERMINAL: {
    title: '不同航站楼中转',
    text: '需在不同航站楼中转，请合理安排转机时间。',
    good: false,
    sortIndex: 5,
  },
  NIGHT_FLIGHT: {
    title: '夜间航班',
    text: '深夜起飞，凌晨或早上到达航班。',
  },
  TRANSFER_AIRPORT: {
    title: '不同机场转机',
    text: 'DEPAIRPORT换乘AIRAIRPORT，请合理安排您的时间和行程。',
    good: false,
    sortIndex: 4,
  },
  NEED_VISA: {
    title: '过境需签证',
    preview: '过境需签证',
    good: false,
    sortIndex: 2,
  },
  NOT_NEED_VISA: {
    preview: '无需过境签',
    title: '无需过境签',
    good: true,
    sortIndex: 2,
  },
  VIRTAUL_FLIGHT_REMARK: {
    preview: '换乘指南',
    title: '换乘指南',
    good: false,
    sortIndex: 9,
  },
  BOOK_NOTE: {
    preview: '预订说明',
    title: '预订说明',
    good: false,
    sortIndex: 10,
  },
  TRANSFER_SERVICE_PACKAGES: {
    preview: '中转服务包',
    title: '中转服务包',
    good: false,
    sortIndex: 11,
  },
  IST_FAST_PASS_REMINDER: {
    preview: '快速通道',
    title: '快速通道',
    good: true,
    sortIndex: 13,
    text: '专属二维码通关，避免在护照检查排队，提升您的转机速度。',
  },
  IST_TRANSIT_SERVICE: {
    preview: '转机服务',
    title: '转机服务',
    good: true,
    sortIndex: 12,
    text: '中转机场柜台提供转机咨询服务，帮助您搭乘下一趟航班。',
  },
};

const HOVER_MESSAGE = new Enum(mergeConfig(Q_CONFIG_KEYS.LIST_FLIGHT_HOVER_MESSAGE, _HOVER_MESSAGE));

export default HOVER_MESSAGE;
